import React, { useEffect, useState } from "react";
import { getMyClassrooms } from "../api/Class";
import { getTerm } from "../api/Term";
import { Select, Row, Col, Typography, Card, Avatar } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import teal from "../assets/vectors/class.svg";
import blue from "../assets/vectors/class1.svg";
import pink from "../assets/vectors/class_pink.svg";
import yellow from "../assets/vectors/class_yellow.svg";
const { Option } = Select;
const { Text } = Typography;
const { Meta } = Card;

export default function MyCLassrooms() {
  const [data, setData] = useState(null);
  const [term, setTerm] = useState(null);
  const [termId, setTermId] = useState(null);

  let colorSet = [
    { dark: "#cf1322", lite: "#ffccc7", image: pink },
    { dark: "#ad6800", lite: "#fff1b8", image: yellow },
    { dark: "#006d75", lite: "#ACD2CA", image: teal },
    { dark: "#1d39c4", lite: "#d6e4ff", image: blue },
  ];

  useEffect(() => {
    getTerm(1, setTerm);
  }, []);

  useEffect(() => {
    if (term && term.terms && term.terms.data) {
      setTermId(term.terms.data[0].id);
    }
  }, [term]);

  useEffect(() => {
    if (termId !== null) getMyClassrooms(termId, setData);
  }, [termId]);

  return (
    <div>
      <Row gutter={[16, 0]} style={{ marginBottom: "1rem" }}>
        <Col xs={{ span: 24 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Please select term"
            value={termId}
            onChange={(value) => {
              setTermId(value);
            }}
          >
            {term && term.terms && term.terms.data
              ? term.terms.data.map((p, k) => (
                  <Option value={p.id} key={p.id}>
                    {p.title}
                  </Option>
                ))
              : ""}
          </Select>
        </Col>
      </Row>
      {data && data.data ? (
        <Row gutter={[16, 16]}>
          {data.data.map((record, l) => (
            <Col xs={{ span: 6 }} key={record.id}>
              <Card
                cover={
                  <div style={{ padding: "1rem 2rem 0 2rem" }}>
                    <img
                      style={{ width: "100%" }}
                      alt="example"
                      src={colorSet[(record.id + 100) % 4].image}
                    />
                  </div>
                }
                actions={[
                  <Link to={`/manage-classroom/${record.id}`}>
                    <Text>Manage Class</Text>
                  </Link>,
                  <Link to={`/manage-classroom/${record.id}`}>
                    <SettingOutlined key="setting" />
                  </Link>,
                ]}
              >
                <Meta
                  style={{ display: "flex", alignItems: "center" }}
                  avatar={
                    <Avatar
                      style={{
                        color: colorSet[(record.id + 100) % 4].dark,
                        backgroundColor: colorSet[(record.id + 100) % 4].lite,
                      }}
                    >
                      {record.subject.name.substring(0, 1)}
                    </Avatar>
                  }
                  title={record.subject.name + " - " + record.level.name}
                />
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
