import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";

export const getTerm = (page, setData) => {
  axios
    .get(Url + `/staff/term`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const addNewTerm = (data, openNotificationWithIcon, setData, page) => {
  axios
    .post(Url + "/staff/term", data)
    .then((res) => {
      openNotificationWithIcon("success", "Success", "Term added Successfully");
      getTerm(page, setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateTerm = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset,
  page
) => {
  axios
    .put(Url + `/staff/term/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Term updated Successfully"
      );
      getTerm(page, setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};
