import axios from "axios";
import Url from "../utils/Urls";
import getError from "../utils/GetError";

export const getAllClass = (setData) => {
  axios
    .get(Url + "/staff/class")
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getMySchedules = (id, setData) => {
  axios
    .get(Url + `/teacher/my-classes/${id}`)
    .then((res) => {
      let sorted =
        res.data && res.data.data && res.data.data.length > 0
          ? res.data.data.sort((a, b) => {
              if (a.day === b.day) {
                return a.schedule.note - b.schedule.note;
              }
              return a.day > b.day ? 1 : -1;
            })
          : [];
      setData(sorted);
    })
    .catch((err) => {
      setData(null);
    });
};
export const getMyClassrooms = (id, setData) => {
  axios
    .get(Url + `/teacher/my-classrooms/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};

export const getSingleClass = (id, setData) => {
  axios
    .get(Url + `/staff/class/${id}`)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      setData(null);
    });
};
export const addNewClass = (data, openNotificationWithIcon, setData) => {
  axios
    .post(Url + "/staff/class", data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Grade added Successfully"
      );
      getAllClass(setData);
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};

export const updateClassInfo = (
  id,
  data,
  openNotificationWithIcon,
  setData,
  reset
) => {
  axios
    .put(Url + `/staff/class/${id}`, data)
    .then((res) => {
      openNotificationWithIcon(
        "success",
        "Success",
        "Grade updated Successfully"
      );
      getAllClass(setData);
      reset();
    })
    .catch((err) => {
      openNotificationWithIcon("error", "Failed", getError(err));
    });
};
