import React, { Suspense, lazy } from "react";
import "./App.less";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import MyCLassrooms from "./pages/MyCLassrooms";

const PasswordReset = lazy(() => import("./pages/PasswordReset"));
const AddHomework = lazy(() => import("./pages/AddHomework"));
const AddClassLink = lazy(() => import("./pages/AddClassLink"));
const AddAnnouncements = lazy(() => import("./pages/AddAnnouncements"));
const AddSyllabus = lazy(() => import("./pages/AddSyllabus"));
const EditEvaluation = lazy(() => import("./pages/EditEvaluation"));
const ManageClassroom = lazy(() => import("./pages/ManageClassroom"));
const MySchedule = lazy(() => import("./pages/MySchedule"));
const HomeworkPagePDF = lazy(() => import("./pages/PDF/HomeworkPagePDF"));
const ReportCardPagePDF = lazy(() => import("./pages/PDF/ReportCardPagePDF"));
const RegPDFPage = lazy(() => import("./pages/PDF/RegPDFPage"));
const BookListPagePDF = lazy(() => import("./pages/PDF/BookListPagePDF"));
const Docs = lazy(() => import("./pages/Docs"));
const AddDoc = lazy(() => import("./pages/AddDoc"));
const Book = lazy(() => import("./pages/Book"));
const EditDoc = lazy(() => import("./pages/EditDoc"));
const Schedule = lazy(() => import("./pages/Schedule"));
const Attendance = lazy(() => import("./pages/Attendance"));
const AttendanceHistory = lazy(() => import("./pages/AttendanceHistory"));
const EditAttendance = lazy(() => import("./pages/EditAttendance"));
const PublishResult = lazy(() => import("./pages/PublishResult"));
const ClassRoutine = lazy(() => import("./pages/ClassRoutine"));
const MyProfile = lazy(() => import("./pages/MyProfile"));
const Salaries = lazy(() => import("./pages/Salaries"));
const AuthRoute = lazy(() => import("./routes/AuthRoute"));
const Students = lazy(() => import("./pages/Students"));
const StudentProfile = lazy(() => import("./pages/StudentProfile"));
const Login = lazy(() => import("./pages/Login"));
const FileType = lazy(() => import("./pages/FileType"));
const ReportCardPage = lazy(() => import("./pages/ReportCardPage"));
const StaffAttendance = lazy(() => import("./pages/StaffAttendance"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const StudentAttendance = lazy(() => import("./pages/StudentAttendance"));
const BookListPage = lazy(() => import("./pages/BookListPage"));
const BookList = lazy(() => import("./pages/BookList"));

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Suspense fallback={<div className="pageProgress"></div>}>
          <div className="App">
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/profile"
                render={() => <AuthRoute Component={<MyProfile />} />}
              />
              <Route
                exact
                path="/"
                render={() => <AuthRoute Component={<MyCLassrooms />} />}
              />
              <Route
                exact
                path="/manage-classroom/:id"
                render={() => <AuthRoute Component={<ManageClassroom />} />}
              />
              <Route
                exact
                path="/add-class-link/:id"
                render={() => <AuthRoute Component={<AddClassLink />} />}
              />
              <Route
                exact
                path="/add-homework/:id"
                render={() => <AuthRoute Component={<AddHomework />} />}
              />
              <Route
                exact
                path="/add-syllabus/:id"
                render={() => <AuthRoute Component={<AddSyllabus />} />}
              />
              <Route
                exact
                path="/add-announcements/:id"
                render={() => <AuthRoute Component={<AddAnnouncements />} />}
              />
              <Route
                exact
                path="/reset-password"
                render={() => <AuthRoute Component={<PasswordReset />} />}
              />
              <Route
                exact
                path="/my-schedule"
                render={() => <AuthRoute Component={<MySchedule />} />}
              />
              <Route
                exact
                path="/students"
                render={() => <AuthRoute Component={<Students />} />}
              />
              <Route
                exact
                path="/student-profile/:id"
                render={() => <AuthRoute Component={<StudentProfile />} />}
              />
              <Route
                exact
                path="/file-type"
                render={() => <AuthRoute Component={<FileType />} />}
              />
              <Route
                exact
                path="/salaries"
                render={() => <AuthRoute Component={<Salaries />} />}
              />
              <Route
                exact
                path="/registration-pdf/:id"
                render={() => <AuthRoute Component={<RegPDFPage />} />}
              />
              <Route
                exact
                path="/book-list-pdf/:id"
                render={() => <AuthRoute Component={<BookListPagePDF />} />}
              />
              <Route
                exact
                path="/homework-pdf/:id"
                render={() => <AuthRoute Component={<HomeworkPagePDF />} />}
              />
              <Route
                exact
                path="/report-card-pdf/:id/:tid"
                render={() => <AuthRoute Component={<ReportCardPagePDF />} />}
              />
              <Route
                exact
                path="/documents"
                render={() => <AuthRoute Component={<Docs />} />}
              />
              <Route
                exact
                path="/add-document"
                render={() => <AuthRoute Component={<AddDoc />} />}
              />
              <Route
                exact
                path="/edit-document/:id"
                render={() => <AuthRoute Component={<EditDoc />} />}
              />
              <Route
                exact
                path="/library"
                render={() => <AuthRoute Component={<Book />} />}
              />
              <Route
                exact
                path="/schedule"
                render={() => <AuthRoute Component={<Schedule />} />}
              />
              <Route
                exact
                path="/attendance/:id/:tid"
                render={() => <AuthRoute Component={<Attendance />} />}
              />
              <Route
                exact
                path="/attendance-history/:id/:tid"
                render={() => <AuthRoute Component={<AttendanceHistory />} />}
              />
              <Route
                exact
                path="/attendance-edit/:id/:lid/:tid"
                render={() => <AuthRoute Component={<EditAttendance />} />}
              />
              <Route
                exact
                path="/result/:id/:lid"
                render={() => <AuthRoute Component={<PublishResult />} />}
              />
              <Route
                exact
                path="/class-routine/"
                render={() => <AuthRoute Component={<ClassRoutine />} />}
              />
              <Route
                exact
                path="/edit-evaluation/:id/:lid"
                render={() => <AuthRoute Component={<EditEvaluation />} />}
              />
              <Route
                exact
                path="/student-attendance"
                render={() => <AuthRoute Component={<StudentAttendance />} />}
              />
              <Route
                exact
                path="/book-list-page"
                render={() => <AuthRoute Component={<BookListPage />} />}
              />
              <Route
                exact
                path="/book-list/:id"
                render={() => <AuthRoute Component={<BookList />} />}
              />
              <Route
                exact
                path="/report-card"
                render={() => <AuthRoute Component={<ReportCardPage />} />}
              />
              <Route
                exact
                path="/staff-attendance"
                render={() => <AuthRoute Component={<StaffAttendance />} />}
              />
              <Route component={ErrorPage} />
            </Switch>
          </div>
        </Suspense>
      </Router>
    </Provider>
  );
}

export default App;
